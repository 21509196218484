import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  IconButton,
  Alert,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import Nav from "../components/Navbar";
import Footer from "../components/Footer";
import { updateSettings } from "../services/api-helper";
import DEFAULT_PROFILE_PICTURE from "../assets/Images/avatar.png";

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": { borderColor: theme.palette.grey[400] },
    "&:hover fieldset": { borderColor: "#007bff" },
    "&.Mui-focused fieldset": { borderColor: "#FF6F61" },
  },
  "& .MuiInputLabel-root": { "&.Mui-focused": { color: "#FF6F61" } },
}));

const Settings = () => {
  const defaultDisabledState = {
    givenName: false,
    familyName: false,
    breweryName: false,
    bio: false,
    socialLinks: false,
    favoriteBrewTypes: false,
  }
  const [formData, setFormData] = useState({
    givenName: "",
    familyName: "",
    breweryName: "",
    bio: "",
    socialLinks: "",
    favoriteBrewTypes: "",
    publicProfile: false,
    profilePicture: "",
  });

  const [errors, setErrors] = useState({});
  const [isEditable, setIsEditable] = useState(defaultDisabledState);
  const [alert, setAlert] = useState(null);

  const validateInput = () => {
    const newErrors = {};
    if (formData.givenName.trim() === "") newErrors.givenName = "First name is required.";
    if (formData.familyName.trim() === "") newErrors.familyName = "Last name is required.";
    if (formData.breweryName.length > 75) newErrors.breweryName = "Brewery name must be under 75 characters.";
    if (formData.bio.length > 500) newErrors.bio = "Bio must be under 500 characters.";
    if (formData.socialLinks.length > 1024) newErrors.socialLinks = "Social links must be under 1024 characters.";
    if (formData.favoriteBrewTypes.length > 200) newErrors.favoriteBrewTypes = "Favorite brew types must be under 200 characters.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = () => {
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = 56;
        canvas.height = 56;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, 56, 56);
        const resizedBase64 = canvas.toDataURL("image/jpeg").split(",")[1];
        setFormData((prevState) => ({
          ...prevState,
          profilePicture: resizedBase64,
        }));
      };
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = async () => {
    if (!validateInput()) return;

    try {
      setIsEditable(defaultDisabledState)
      await updateSettings(formData);
      setAlert({ type: "success", message: "Settings updated successfully!" });
    } catch (error) {
      console.error("Error updating settings:", error);
      setAlert({ type: "error", message: "Failed to update settings. Please try again." });
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const toggleEdit = (field) => {
    setIsEditable((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  useEffect(() => {
    const session = JSON.parse(localStorage.getItem("userSession") || "{}");
    if (session?.userInfo) {
      setFormData({
        givenName: session.userInfo.given_name || "",
        familyName: session.userInfo.family_name || "",
        breweryName: session.userInfo["custom:breweryName"] || "",
        bio: session.userInfo["custom:bio"] || "",
        socialLinks: session.userInfo["custom:socialLinks"] || "",
        favoriteBrewTypes: session.userInfo["custom:favoriteBrewTypes"] || "",
        publicProfile: session.userInfo["custom:publicProfile"] || false,
        profilePicture: session.userInfo["custom:profilePicture"] || "",
      });
    }
  }, []);

  return (
    <>
      <Nav />
      <Box
        sx={{
          minHeight: "calc(100vh - 150px)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          px: { xs: "1rem", sm: "2rem", md: "4rem", lg: "6rem" },
          backgroundColor: "#F8F9FA",
        }}
      >
        <Typography variant="h2" textAlign="center" my={3}>
          Settings
        </Typography>
        {alert && (
          <Alert
            severity={alert.type}
            onClose={() => setAlert(null)}
            sx={{ mb: 3, maxWidth: "600px", mx: "auto" }}
          >
            {alert.message}
          </Alert>
        )}
        <Box
          sx={{
            maxWidth: "600px",
            mx: "auto",
            p: 3,
            backgroundColor: "#fff",
            borderRadius: "1rem",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", mb: 3, position: "relative" }}>
            <img
              src={formData.profilePicture || DEFAULT_PROFILE_PICTURE}
              alt="Profile"
              style={{
                width: "80px",
                height: "80px",
                borderRadius: "50%",
                marginRight: "1rem",
                border: "1px solid #ccc",
                objectFit: "cover",
              }}
            />
            {/* <IconButton
              component="label"
              sx={{
                position: "absolute",
                bottom: 0,
                left: "40px",
                backgroundColor: "white",
              }}
            >
              <EditIcon />
              <input
                type="file"
                accept="image/jpeg,image/png"
                hidden
                onChange={handleImageUpload}
              />
            </IconButton> */}
            <Box>
              <Typography variant="h6" sx={{ mb: 0.5 }}>
                {`${formData.givenName} ${formData.familyName}`}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {formData.breweryName}
              </Typography>
            </Box>
          </Box>
          <Grid container spacing={2}>
            {Object.entries(formData).map(([field, value]) => {
              if (field === "publicProfile" || field === "profilePicture") return null;

              return (
                <Grid item xs={12} key={field}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <StyledTextField
                      label={field.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase())}
                      name={field}
                      value={value}
                      onChange={handleChange}
                      fullWidth
                      disabled={!isEditable[field]}
                      error={!!errors[field]}
                      helperText={errors[field]}
                      sx={{ flexGrow: 1, mb: 0 }}
                    />
                    <IconButton onClick={() => toggleEdit(field)} sx={{ ml: 1 }}>
                      <EditIcon />
                    </IconButton>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
          <FormControlLabel
            control={
              <Checkbox
                name="publicProfile"
                checked={formData.publicProfile === "true"}
                onChange={handleChange}
              />
            }
            label="Make profile public"
          />
          <Box sx={{ textAlign: "center", mt: 3 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              sx={{ backgroundColor: "#FF6F61", color: "#fff" }}
            >
              Save Changes
            </Button>
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default Settings;
