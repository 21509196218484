import {
  Alert,
  Box,
  Grid,
  Typography,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState, useCallback } from "react";
import { getUserPublishedBrews } from "../services/api-helper";
import Nav from "../components/Navbar";
import Footer from "../components/Footer";
import DEFAULT_IMAGE_URL from "../assets/Images/defaultimage.png";

const ReadOnlyBrewCard = ({ brew }) => {
  const [loadingImage, setLoadingImage] = useState(true);
  const imageUrl = brew.imageUrl || DEFAULT_IMAGE_URL;

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Box
        sx={{
          border: "1px solid #ccc",
          borderRadius: "1rem",
          p: 2,
          textAlign: "center",
          position: "relative",
          minHeight: 300,
        }}
      >
        {loadingImage && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <Typography variant="h6">{brew.brewName}</Typography>
        <img
          src={imageUrl}
          alt={brew.brewName}
          onLoad={() => setLoadingImage(false)}
          onError={() => setLoadingImage(false)}
          style={{
            width: "100%",
            height: "200px",
            borderRadius: "1rem",
            objectFit: "contain",
            marginTop: "1rem",
            display: loadingImage ? "none" : "block",
          }}
        />
        <Typography variant="p">{brew.description}</Typography>
      </Box>
    </Grid>
  );
};

const ExploreBrews = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const userId = queryParams.get("userId") || "";

  const [allBrews, setAllBrews] = useState([]);
  const [displayName, setDisplayName] = useState("");
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);

  const fetchBrews = useCallback(async () => {
    setLoading(true);
    try {
      // Call the API to get user-published brews, which returns an object with displayName and brews.
      const data = await getUserPublishedBrews(userId);
      setAllBrews(data.brews);
      setDisplayName(data.displayName);
    } catch (error) {
      console.error("Error fetching published brews:", error);
      setAlert({
        type: "error",
        message: "Failed to fetch brews. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  }, [userId]);

  // Always call useEffect in the same order. Conditionally do the fetch if we have a userId.
  useEffect(() => {
    if (userId) {
      fetchBrews();
    }
  }, [userId, fetchBrews]);

  // Conditionally render the "Brew page not found" if userId is empty.
  if (!userId) {
    return (
      <>
        <Nav />
        <Box
          sx={{
            minHeight: "calc(100vh - 150px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            px: { xs: "1rem", sm: "2rem", md: "4rem", lg: "6rem" },
            backgroundColor: "#F8F9FA",
          }}
        >
          <Typography variant="h5" color="error" sx={{ mb: 3 }}>
            Brew page not found, user does not exist
          </Typography>
        </Box>
        <Footer />
      </>
    );
  }

  // If we have a valid userId, render the brews.
  return (
    <>
      <Nav />
      <Box
        sx={{
          minHeight: "calc(100vh - 150px)",
          display: "flex",
          flexDirection: "column",
          justifyContent:
            allBrews.length === 0 && !loading ? "center" : "flex-start",
          px: { xs: "1rem", sm: "2rem", md: "4rem", lg: "6rem" },
          backgroundColor: "#F8F9FA",
        }}
      >
        {alert && (
          <Alert
            severity={alert.type}
            onClose={() => setAlert(null)}
            sx={{ mb: 3, maxWidth: "600px", mx: "auto" }}
          >
            {alert.message}
          </Alert>
        )}
        <Grid container>
          <Grid item xs={12} textAlign="center" my={3}>
            <Typography variant="h2">
              {displayName ? `${displayName}'s Brews` : ""}
            </Typography>
            <Typography variant="body1" color="gray">
              Browse the list of brews!
            </Typography>
          </Grid>
        </Grid>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: 200,
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={2}>
            {allBrews.map((brew, index) => (
              <ReadOnlyBrewCard key={brew.BrewId || `index-${index}`} brew={brew} />
            ))}
          </Grid>
        )}
      </Box>
      <Footer />
    </>
  );
};

export default ExploreBrews;
