// App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { Provider } from 'react-redux';

import Home from "./Screens/Home";
import SignIn from "./Screens/SignIn";
import SignUp from "./Screens/signUp";
import SignUpSuccess from "./Screens/SignUpSuccess";
import BrewCollection from "./Screens/BrewCollection";
import ExploreBrews from "./Screens/ExploreBrews";
import ForgetPassword from "./Screens/forgetPassword";
import BrewNotes from "./Screens/BrewNotes";
import Settings from "./Screens/Settings";
import ShareableBrew from "./Screens/shareableBrew";

import { SessionProvider } from "./Providers/SessionProvider";
import { store } from './redux/store';
import ProtectedRoute from "./components/ProtectedRoute";

function App() {
    return (
        <Provider store={store}>
            <Router>
                {/* The SessionProvider will check and update the session before rendering children */}
                <SessionProvider>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/signin" element={<SignIn />} />
                        <Route path="/signup" element={<SignUp />} />
                        <Route path="/signupsuccess" element={<SignUpSuccess />} />
                        <Route path="/viewbrews" element={<ExploreBrews />} />
                        <Route path="/forgot-password" element={<ForgetPassword />} />
                        <Route path="/shareable-brew" element={<ShareableBrew />} />

                        {/* Protected Routes */}
                        <Route
                            path="/mybrews"
                            element={
                                <ProtectedRoute>
                                    <BrewCollection />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/notes"
                            element={
                                <ProtectedRoute>
                                    <BrewNotes />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/settings"
                            element={
                                <ProtectedRoute>
                                    <Settings />
                                </ProtectedRoute>
                            }
                        />
                        <Route path="*" element={<Navigate to="/" replace />} />
                    </Routes>
                </SessionProvider>
            </Router>
        </Provider>
    );
}

export default App;
