import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Footer from "../components/Footer";
import Nav from "../components/Navbar";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  createBrew,
  getAllMyBrews,
  deleteBrew,
  updateBrew,
} from "../services/api-helper";
import DEFAULT_IMAGE_URL from "../assets/Images/defaultimage.png";

// =============================================================================
// • Styled components (as before)
// =============================================================================
const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": { borderColor: theme.palette.grey[400] },
    "&:hover fieldset": { borderColor: "#007bff" },
    "&.Mui-focused fieldset": { borderColor: "#FF6F61" },
  },
  "& .MuiInputLabel-root": { "&.Mui-focused": { color: "#FF6F61" } },
}));

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

// =============================================================================
// • BrewCard Component
// =============================================================================
const BrewCard = ({ brew, onEdit, onDelete }) => {
  const [loadingImage, setLoadingImage] = useState(true);
  const imageUrl = brew.imageUrl || DEFAULT_IMAGE_URL;

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Box
        sx={{
          border: "1px solid #ccc",
          borderRadius: "1rem",
          p: 2,
          textAlign: "center",
          position: "relative",
          minHeight: 300,
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        {loadingImage && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 200,
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <img
          src={imageUrl}
          alt={brew.brewName}
          onLoad={() => setLoadingImage(false)}
          onError={() => setLoadingImage(false)}
          style={{
            width: "100%",
            height: "200px",
            borderRadius: "1rem",
            objectFit: "contain",
            marginTop: "1rem",
            display: loadingImage ? "none" : "block",
          }}
        />
        <Typography variant="h6" sx={{ mt: 1 }}>
          {brew.brewName}
        </Typography>
        <Box mt={2}>
          <IconButton onClick={() => onEdit(brew)} color="primary">
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => onDelete(brew.BrewId)} color="error">
            <DeleteIcon />
          </IconButton>
        </Box>
        <Button
          variant="contained"
          sx={{ mt: 2, backgroundColor: "#FF6F61", color: "#fff" }}
          onClick={() => {
            window.location.href = `/notes?brewId=${brew.BrewId}`;
          }}
        >
          View Notes
        </Button>
      </Box>
    </Grid>
  );
};

// =============================================================================
// • AddBrewCard Component
// =============================================================================
// This card mimics the BrewCard dimensions and style but shows only a "+" icon.
// It has a hover effect that changes its border color.
const AddBrewCard = ({ onClick }) => (
  <Grid item xs={12} sm={6} md={4}>
    <Box
      onClick={onClick}
      sx={{
        height: "91.8%",
        border: "1px solid #ccc",
        borderRadius: "1rem",
        p: 2,
        textAlign: "center",
        position: "relative",
        minHeight: 300,
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        cursor: "pointer",
        transition: "border-color 0.3s",
        "&:hover": {
          borderColor: "#FF6F61",
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <IconButton color="primary" sx={{ fontSize: "3rem" }}>
        <AddIcon sx={{ fontSize: "3rem" }} />
      </IconButton>
    </Box>
  </Grid>
);

// =============================================================================
// • BrewCollection Component
// =============================================================================
const BrewCollection = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allBrews, setAllBrews] = useState([]);
  const [selectedBrew, setSelectedBrew] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [loading, setLoading] = useState(false); // For fetching brews
  const [actionLoading, setActionLoading] = useState(false); // For create/update/delete actions

  // Brew details for both create and edit forms:
  const [brewName, setBrewName] = useState("");
  const [brewImage, setBrewImage] = useState(null);
  const [currentImagePreview, setCurrentImagePreview] = useState("");
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [published, setPublished] = useState(false);
  const [description, setDescription] = useState("");
  const [brewType, setBrewType] = useState("");
  const [brewStyle, setBrewStyle] = useState("");
  const [alert, setAlert] = useState(null);

  // Function to copy the share URL to the clipboard.
  const shareBrewPage = () => {
    try {
      const session = JSON.parse(localStorage.getItem("userSession") || "{}");
      const userInfo = session?.userInfo;
      if (userInfo?.sub) {
        const shareUrl = `${window.location.origin}/viewbrews?userId=${userInfo.sub}`;
        navigator.clipboard.writeText(shareUrl).then(() => {
          setAlert({ type: "success", message: "Brew page URL copied to clipboard!" });
        });
      } else {
        setAlert({ type: "error", message: "User information not found." });
      }
    } catch (err) {
      console.error("Error copying brew page URL", err);
      setAlert({ type: "error", message: "Failed to copy URL." });
    }
  };

  // Clear fields used in the modals.
  const clearFields = () => {
    setBrewImage(null);
    setBrewName("");
    setDescription("");
    setSelectedDate(dayjs());
    setBrewType("");
    setBrewStyle("");
    setPublished(false);
    setCurrentImagePreview("");
  };

  // Fetch all brews from the API
  const fetchBrews = async () => {
    setLoading(true);
    try {
      const response = await getAllMyBrews();
      // Ensure each Brew has an image URL (or use a default)
      const updatedBrews = response.map((brew) => ({
        ...brew,
        imageUrl: brew.imageUrl || DEFAULT_IMAGE_URL,
      }));
      setAllBrews(updatedBrews);
    } catch (error) {
      setAlert({
        type: "error",
        message: "Failed to fetch your Brews. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  // When clicking the edit icon
  const handleEditBrew = (brew) => {
    setSelectedBrew(brew);
    setBrewName(brew.brewName || "");
    setBrewType(brew.brewType || "");
    setDescription(brew.description || "");
    setSelectedDate(brew.date ? dayjs(brew.date, "MM/DD/YYYY") : dayjs());
    setBrewStyle(brew.brewStyle || "");
    setPublished(brew.published || false);
    setCurrentImagePreview(brew.imageUrl || DEFAULT_IMAGE_URL);
    setIsEditModalOpen(true);
  };

  // Update brew (edit form)
  const handleUpdateBrew = async () => {
    if (!brewName) {
      alert("Brew name is required.");
      return;
    }
    setActionLoading(true);
    try {
      let imageBase64 = null;
      if (brewImage) {
        const reader = new FileReader();
        reader.onload = async () => {
          imageBase64 = reader.result.split(",")[1];
          await saveUpdate(imageBase64);
        };
        reader.readAsDataURL(brewImage);
      } else {
        await saveUpdate(imageBase64);
      }
    } catch (error) {
      setAlert({
        type: "error",
        message: "Failed to upload image. Please try again.",
      });
      setActionLoading(false);
    }
  };

  const saveUpdate = async (imageBase64) => {
    try {
      await updateBrew(selectedBrew.BrewId, {
        brewName,
        description,
        imageBase64,
        imageType: brewImage?.type || null,
        brewType,
        brewStyle,
        published,
        date: selectedDate ? selectedDate.format("MM/DD/YYYY") : null,
      });
      await fetchBrews();
      setIsEditModalOpen(false);
      clearFields();
      setAlert({ type: "success", message: "Brew updated successfully!" });
    } catch (error) {
      setAlert({
        type: "error",
        message: "Failed to update Brew. Please try again.",
      });
    } finally {
      setActionLoading(false);
    }
  };

  // Create a new brew
  const handleAddBrew = async () => {
    if (!brewName) {
      alert("Brew name is required.");
      return;
    }
    setActionLoading(true);
    const formattedDate = selectedDate ? selectedDate.format("MM/DD/YYYY") : null;
    if (brewImage) {
      const reader = new FileReader();
      reader.onload = async () => {
        const imageBase64 = reader.result.split(",")[1];
        try {
          await createBrew({
            brewName,
            description: "",
            imageBase64,
            brewType,
            imageType: brewImage?.type || null,
            date: formattedDate,
          });
          clearFields();
          await fetchBrews();
          setIsModalOpen(false);
          setAlert({
            type: "success",
            message: "Successfully created new Brew!",
          });
        } catch (error) {
          setAlert({
            type: "error",
            message: "Failed to create new Brew. Please try again.",
          });
        } finally {
          setActionLoading(false);
        }
      };
      reader.readAsDataURL(brewImage);
    } else {
      try {
        await createBrew({
          brewName,
          description: "",
          brewType,
          date: formattedDate,
        });
        clearFields();
        await fetchBrews();
        setIsModalOpen(false);
        setAlert({
          type: "success",
          message: "Brew created successfully!",
        });
      } catch (error) {
        setAlert({
          type: "error",
          message: "Failed to create Brew. Please try again.",
        });
      } finally {
        setActionLoading(false);
      }
    }
  };

  // Delete a brew
  const handleDeleteBrew = async (brewId) => {
    setActionLoading(true);
    try {
      await deleteBrew(brewId);
      await fetchBrews();
      setAlert({ type: "success", message: "Brew deleted successfully!" });
    } catch (error) {
      setAlert({
        type: "error",
        message: "Failed to delete brew. Please try again.",
      });
    } finally {
      setActionLoading(false);
    }
  };

  useEffect(() => {
    fetchBrews();
  }, []);

  return (
    <>
      <Nav />
      {loading ? (
        <Box
          sx={{
            minHeight: "calc(100vh - 150px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            minHeight: "calc(100vh - 150px)",
            display: "flex",
            flexDirection: "column",
            justifyContent: allBrews.length === 0 ? "center" : "flex-start",
            px: { xs: "1rem", sm: "2rem", md: "4rem", lg: "6rem" },
            backgroundColor: "#F8F9FA",
          }}
        >
          {alert && (
            <Alert
              severity={alert.type}
              onClose={() => setAlert(null)}
              sx={{ mb: 3, maxWidth: "600px", mx: "auto" }}
            >
              {alert.message}
            </Alert>
          )}
          <Grid container>
            <Grid item xs={12} textAlign="center" my={3}>
              <Typography variant="h2">My Brew Collection</Typography>
              <Typography variant="body1" color="gray">
                Track, edit, and showcase your unique creations.
              </Typography>
            </Grid>
          </Grid>
          {/* New "Share My Brew Page" button */}
          <Box textAlign="center" mb={2}>
            <Button
              variant="contained"
              onClick={shareBrewPage}
              disabled={loading || actionLoading}
              sx={{ backgroundColor: "#FF6F61", color: "#fff" }}
            >
              Share My Link
            </Button>
          </Box>

          {/* 
            Instead of a separate "Add New Brew" button, we now render the brew cards
            and append the AddBrewCard as the last grid item.
            Material UI's Grid will handle the row layout (3 per row) so that:
              - If there are 1 or 2 brews in the last row, the AddBrewCard fills the next cell.
              - If the last row has 3 brews, the AddBrewCard appears as the first item in a new row.
          */}
          <Grid container spacing={2} alignItems="stretch">
            {allBrews.map((brew, index) => (
              <BrewCard
                key={brew.brewId || `index-${index}`}
                brew={brew}
                onEdit={handleEditBrew}
                onDelete={handleDeleteBrew}
              />
            ))}
            <AddBrewCard onClick={() => setIsModalOpen(true)} />
          </Grid>
        </Box>
      )}

      {/* ========================
         Edit Brew Modal
      =========================== */}
      <Modal
        open={isEditModalOpen}
        onClose={() => {
          setIsEditModalOpen(false);
          clearFields();
        }}
        aria-labelledby="edit-modal-title"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: { xs: "90%", sm: "70%", md: "50%" },
            maxHeight: "90vh",
            padding: 2,
            backgroundColor: "#fff",
            borderRadius: "1rem",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            display: "flex",
            flexDirection: "column",
            overflow: "auto",
            scrollbarWidth: "thin",
            scrollbarColor: "#FF6F61 #f0f0f0",
            "&::-webkit-scrollbar": { width: "8px" },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#f0f0f0",
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#FF6F61",
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#FF3F41",
            },
          }}
        >
          <Typography
            variant="h5"
            sx={{ color: "#FF6F61", mb: 2, textAlign: "center" }}
          >
            Edit Brew
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              columnGap: 2,
              rowGap: 1,
            }}
          >
            <StyledTextField
              label="Brew Name"
              value={brewName}
              onChange={(e) => setBrewName(e.target.value)}
              fullWidth
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date"
                value={selectedDate}
                onChange={(newValue) => setSelectedDate(newValue)}
                slotProps={{ textField: { fullWidth: true } }}
              />
            </LocalizationProvider>
            <TextField
              select
              label="Brew Type"
              value={brewType}
              onChange={(e) => setBrewType(e.target.value)}
              fullWidth
              SelectProps={{
                native: true,
              }}
              required
            >
              <option value=""></option>
              <option value="mead">Mead</option>
              <option value="wine">Wine</option>
              <option value="beer">Beer</option>
              <option value="kombucha">Kombucha</option>
              <option value="soda">Soda</option>
            </TextField>
            <StyledTextField
              label="Brew Style"
              value={brewStyle}
              onChange={(e) => setBrewStyle(e.target.value)}
              fullWidth
            />
          </Box>
          <StyledTextField
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
            multiline
            rows={2}
            sx={{ mt: 2 }}
          />
          <Box sx={{ mt: 2, textAlign: "center" }}>
            <Button
              variant="contained"
              component="label"
              fullWidth
              disabled={actionLoading}
              sx={{ backgroundColor: "#FF6F61", mb: 1 }}
            >
              Upload New Image
              <VisuallyHiddenInput
                type="file"
                accept="image/jpeg, image/png"
                onChange={(e) => {
                  setBrewImage(e.target.files[0]);
                  setCurrentImagePreview(
                    URL.createObjectURL(e.target.files[0])
                  );
                }}
              />
            </Button>
            {currentImagePreview && (
              <Box>
                <Typography variant="body2">Image Preview:</Typography>
                <img
                  src={currentImagePreview}
                  alt="Preview"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100px",
                    objectFit: "contain",
                    borderRadius: "0.5rem",
                    border: "1px solid #ccc",
                    marginTop: "0.5rem",
                  }}
                />
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 3,
              flexDirection: { xs: "column", md: "row" },
              gap: 2,
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={published}
                  onChange={(e) => setPublished(e.target.checked)}
                />
              }
              label="Publish"
              sx={{ marginRight: { xs: 0, md: 2 } }}
            />
            <Box sx={{ display: "flex", gap: 2 }}>
              <Button
                variant="contained"
                onClick={() => {
                  setIsEditModalOpen(false);
                  clearFields();
                }}
                disabled={actionLoading}
                sx={{
                  backgroundColor: "#D6D6D6",
                  color: "#000",
                  fontSize: "0.9rem",
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleUpdateBrew}
                disabled={actionLoading}
                sx={{
                  backgroundColor: "#FF6F61",
                  color: "#fff",
                  fontSize: "0.9rem",
                }}
              >
                Save Changes
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* ========================
         Add Brew Modal
      =========================== */}
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: 2,
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", sm: "80%", md: "50%" },
            maxHeight: "90vh",
            overflow: "auto",
            backgroundColor: "#fff",
            borderRadius: "1rem",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            p: 3,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <form>
            <Typography variant="h5" textAlign="center" gutterBottom>
              Add New Brew
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: 2,
                my: 2,
              }}
            >
              <StyledTextField
                label="Brew Name"
                fullWidth
                variant="outlined"
                value={brewName}
                onChange={(e) => setBrewName(e.target.value)}
                required
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: 2,
                my: 2,
              }}
            >
              <TextField
                select
                label="Brew Type"
                value={brewType}
                onChange={(e) => setBrewType(e.target.value)}
                fullWidth
                SelectProps={{
                  native: true,
                }}
                required
              >
                <option value=""></option>
                <option value="mead">Mead</option>
                <option value="wine">Wine</option>
                <option value="beer">Beer</option>
                <option value="kombucha">Kombucha</option>
                <option value="soda">Soda</option>
              </TextField>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Select a Date"
                  value={selectedDate}
                  onChange={(newValue) => setSelectedDate(newValue)}
                  slotProps={{ textField: { fullWidth: true } }}
                  required
                />
              </LocalizationProvider>
            </Box>
            <Button
              variant="contained"
              component="label"
              fullWidth
              disabled={actionLoading}
              sx={{ backgroundColor: "#FF6F61", mb: 2 }}
            >
              Upload Image
              <VisuallyHiddenInput
                type="file"
                accept="image/jpeg, image/png"
                onChange={(e) => setBrewImage(e.target.files[0])}
              />
            </Button>
            {brewImage && (
              <Box mt={2} textAlign="center">
                <Typography variant="body2">Image Preview:</Typography>
                <img
                  src={URL.createObjectURL(brewImage)}
                  alt="Preview"
                  style={{
                    maxWidth: "100%",
                    height: "200px",
                    objectFit: "contain",
                    borderRadius: "0.5rem",
                    border: "1px solid #ccc",
                  }}
                />
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                flexDirection: { xs: "column", sm: "row" },
                gap: 2,
                mt: 3,
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  clearFields();
                  setIsModalOpen(false);
                }}
                disabled={actionLoading}
                sx={{ backgroundColor: "#D6D6D6", color: "#000" }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleAddBrew}
                disabled={actionLoading}
                sx={{ backgroundColor: "#FF6F61", color: "#fff" }}
              >
                Save Brew
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
      <Footer />
    </>
  );
};

export default BrewCollection;
