import React, { useState } from "react";
import { AppBar, Toolbar, Button, IconButton, Drawer, List, ListItem, ListItemText, ListItemButton, Box, Grid, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import brewLogo from "../assets/Images/breLogoBlack.png";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { useNavigate } from "react-router-dom";
import avatar from "../assets/Images/avatar.png";
import beer from "../assets/Images/beer.png";
import noteBook from "../assets/Images/notebook.png";
import overView from "../assets/Images/overview.png";
import exit from "../assets/Images/exit.png";
import setting from "../assets/Images/settings.png";
import { useDispatch, useSelector } from "react-redux";
import { clearSession } from "../redux/slices/notesSlices/AuthSlice";
import { logoutUser } from "../services/auth-utils";

const Nav = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const { isAuthenticated, userInfo } = useSelector((state) => state.Auth);
  const navigation = useNavigate();
  const dispatch = useDispatch();

  const profilePicture = userInfo?.attributes?.profilePicture || avatar;

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleSignIn = () => {
    navigation("/signin");
  };

  const handleSignUp = () => {
    navigation("/signup");
  };

  const handleNavigate = (path) => {
    navigation(path);
    setDrawerOpen(false);
  };

  const handleLogout = async () => {
    logoutUser();
    dispatch(clearSession());
    navigation("/", { replace: true });
  };

  const renderAuthSection = () => {
    if (!isAuthenticated) {
      return (
        <Box>
          <Button
            onClick={handleSignIn}
            variant="contained"
            sx={{
              ml: 4,
              borderColor: "#000000",
              backgroundColor: "#FF6F61",
              color: "#F0F0F0",
              borderRadius: "20px",
              width: "150px",
              height: "40px",
              fontFamily: "Poppins",
            }}
            endIcon={<ArrowOutwardIcon />}
          >
            Sign In
          </Button>
          <Button
            onClick={handleSignUp}
            variant="contained"
            sx={{
              ml: 4,
              borderColor: "#000000",
              backgroundColor: "#FF6F61",
              color: "#F0F0F0",
              borderRadius: "20px",
              width: "150px",
              height: "40px",
              fontFamily: "Poppins",
            }}
            endIcon={<ArrowOutwardIcon />}
          >
            Sign Up
          </Button>
        </Box>

      );
    }

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
          ml: 4,
          cursor: "pointer",
        }}
        onMouseEnter={() => setTooltipVisible(true)}
      >
        <Box
          sx={{
            width: { xs: "35px", sm: "50px", md: "50px" },
            height: { xs: "35px", sm: "50px", md: "50px" },
            borderRadius: "50%",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={profilePicture}
            alt="Avatar"
            style={{
              width: "100%",
              height: "auto",
              objectFit: "cover",
            }}
          />
        </Box>

        {tooltipVisible && (
          <Box
            onMouseEnter={() => setTooltipVisible(true)}
            onMouseLeave={() => setTooltipVisible(false)}
            sx={{
              opacity: 1,
              // transform: "translateY(0)",
              transition: "opacity 0.3s ease, transform 0.3s ease",
              position: "absolute",
              top: "100%",
              transform: "translateX(-50%) translateY(10px)",
              backgroundColor: "#fff",
              padding: 2,
              borderRadius: 2,
              border: "0.5px solid #bfbfbf",
              zIndex: 1,
              width: "250%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            {/* Dropdown Menu Items */}
            <MenuOption
              icon={beer}
              text="My Brews"
              onClick={() => handleNavigate("/mybrews")}
            />
            {/* <MenuOption
              icon={overView}
              text="View Brews"
              onClick={() => handleNavigate("/ExploreBrews")}
            /> */}
            {/* <MenuOption
              icon={noteBook}
              text="My Notes"
              onClick={() => handleNavigate("/notes")}
            /> */}
            <MenuOption
              icon={setting}
              text="Settings"
              onClick={() => handleNavigate("/settings")}
            />
            <Box sx={{ width: "100%", border: "1px solid lightgray", my: 1 }} />
            <MenuOption
              icon={exit}
              text="Sign Out"
              onClick={handleLogout}
            />
          </Box>
        )}
      </Box>
    );
  };

  const MenuOption = ({ icon, text, onClick }) => (
    <Box
      onClick={() => {
        onClick();
        setTooltipVisible(false);
      }}
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: 2,
        padding: "8px 0",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "#f5f5f5",
        },
      }}
    >
      <img
        src={icon}
        alt={text}
        style={{
          width: "15%",
          height: "auto",
          objectFit: "cover",
        }}
      />
      <Typography sx={{ color: "#000", width: "100%" }}>
        {text}
      </Typography>
    </Box>
  );

  return (
    <AppBar position="static" sx={{ backgroundColor: "#FFFFFF", py: 2, boxShadow: "none" }}>
      <Toolbar>
        <Grid container spacing={2} justifyContent="space-between" alignItems="center" px={{ xs: 2, sm: 2, md: 12 }}>
          {/* Logo */}
          <Grid item xs={4} sm={2} md={2}>
            <Box
              onClick={() => navigation("/")}
              sx={{
                maxWidth: { xs: "110px", sm: "140px", md: "220px" },
                width: "100%",
                height: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <img src={brewLogo} alt="Logo" style={{ width: "100%", height: "auto", objectFit: "contain" }} />
            </Box>
          </Grid>

          {/* Navigation Links */}
          <Grid item sm={8} md={8} sx={{ display: { xs: "none", sm: "flex" }, justifyContent: "flex-end", alignItems: "center" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {/* <Button onClick={() => handleNavigate("/about")} sx={{ color: "#000000", fontFamily: "Poppins" }}>
                About
              </Button>
              <Button onClick={() => handleNavigate("/blog")} sx={{ color: "#000000", fontFamily: "Poppins", mx: 2 }}>
                Blog
              </Button>
              <Button onClick={() => handleNavigate("/contact")} sx={{ color: "#000000", fontFamily: "Poppins" }}>
                Contact
              </Button> */}
              {renderAuthSection()}
            </Box>
          </Grid>

          {/* Mobile Menu */}
          <Grid item xs={2} sx={{ display: { xs: "block", sm: "none" } }}>
            <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleDrawerToggle} sx={{ color: "#000000" }}>
              <MenuIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>

      {/* Mobile Drawer */}
      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
        <List sx={{ width: 250 }}>
          {isAuthenticated ? (
            <>
              <ListItem disablePadding>
                <ListItemButton onClick={() => handleNavigate("/mybrews")}>
                  <ListItemText primary="My Brews" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={() => handleNavigate("/ExploreBrews")}>
                  <ListItemText primary="View Brews" />
                </ListItemButton>
              </ListItem>
              {/* <ListItem disablePadding>
                <ListItemButton onClick={() => handleNavigate("/notes")}>
                  <ListItemText primary="My Notes" />
                </ListItemButton>
              </ListItem> */}
              <ListItem disablePadding>
                <ListItemButton onClick={handleLogout}>
                  <ListItemText primary="Sign Out" />
                </ListItemButton>
              </ListItem>
            </>
          ) : (
            <>
              <ListItem disablePadding>
                <ListItemButton onClick={handleSignIn}>
                  <ListItemText primary="Sign In" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={handleSignUp}>
                  <ListItemText primary="Sign Up" />
                </ListItemButton>
              </ListItem>
            </>
          )}
        </List>
      </Drawer>
    </AppBar>
  );
};

export default Nav;