import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Alert,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import moment from "moment";
import useMediaQuery from "@mui/material/useMediaQuery";
import Nav from "../components/Navbar";
import Footer from "../components/Footer";
import { getBrew, getNotes, addNote, updateNote } from "../services/api-helper";
import noteSchemas from "../components/noteSchema";

const steps = ["Category", "Details", "Review & Submit"];

const categoryLabels = {
  recipeandpreparation: "Recipe & Preparation",
  primaryfermentation: "Primary Fermentation",
  secondaryfermentation: "Secondary Fermentation",
  packaging: "Packaging",
  tastingandfinalnotes: "Tasting",
};

function formatCategory(key) {
  return categoryLabels[key.toLowerCase()] || key;
}

const orderedCategories = [
  "recipeandpreparation",
  "primaryfermentation",
  "secondaryfermentation",
  "packaging",
  "tastingandfinalnotes",
];

const categoryColors = {
  recipeandpreparation: "rgba(255, 99, 132, 0.2)",
  primaryfermentation: "rgba(54, 162, 235, 0.2)",
  secondaryfermentation: "rgba(255, 206, 86, 0.2)",
  packaging: "rgba(75, 192, 192, 0.2)",
  tastingandfinalnotes: "rgba(153, 102, 255, 0.2)",
};

const BrewNotes = () => {
  const location = useLocation();
  const brewId = new URLSearchParams(location.search).get("brewId");

  // Use media query to detect mobile view (600px or less)
  const isMobile = useMediaQuery("(max-width:600px)");

  // Set default viewMode based on viewport size
  const [viewMode, setViewMode] = useState(isMobile ? "table" : "kanban");

  const initialNoteState = () => ({
    brewId: brewId,
    category: "",
    description: "",
    date: dayjs(),
  });

  // Default to Free Text Mode
  const [isFreeText, setIsFreeText] = useState(true);
  const [freeTextBody, setFreeTextBody] = useState("");
  const [notes, setNotes] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [brewType, setBrewType] = useState("");
  const [currentNote, setCurrentNote] = useState(initialNoteState());
  const [isEditing, setIsEditing] = useState(false);
  const [brewName, setBrewName] = useState("");
  // Error state for friendly error messages.
  const [error, setError] = useState(null);

  // If the viewport is mobile, force table view.
  useEffect(() => {
    if (isMobile) {
      setViewMode("table");
    }
  }, [isMobile]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Reset any previous error.
        setError(null);
        const [notesResponse, brewResponse] = await Promise.all([
          getNotes(brewId),
          getBrew(brewId),
        ]);

        // Check if brewResponse is empty (brew not found)
        if (notesResponse.message &&
          notesResponse.message.includes("not Found")) {
          setError("Brew not found.");
          return;
        }

        // Check if notesResponse indicates an authorization error.
        if (
          notesResponse.message &&
          notesResponse.message.includes("not authorized")
        ) {
          setError(
            "You are not authorized to view these notes."
          );
          return;
        }

        // Process notes into a categorized object.
        const categorizedNotes = notesResponse.notes.reduce((acc, note) => {
          const category = note.Title.toLowerCase();
          if (!acc[category]) {
            acc[category] = [];
          }
          acc[category].push({
            ...note,
            date: moment(note.Timestamp).format("YYYY-MM-DD"),
            description: note.Body,
            category: note.Title,
          });
          return acc;
        }, {});

        setNotes(categorizedNotes);
        setBrewType(brewResponse[0]?.brewType || "");
        setBrewName(brewResponse[0]?.brewName);
      } catch (error) {
        setError(
          "An error occurred while fetching your brew notes."
        );
      }
    };
    fetchData();
  }, [brewId]);

  const handleModalToggle = (open) => {
    setModalOpen(open);
    if (!open) {
      setActiveStep(0);
      setCurrentNote(initialNoteState());
      setIsEditing(false);
      setFreeTextBody("");
    }
  };

  // When a note (card or row) is clicked, open the modal with that note.
  const handleCardClick = (note) => {
    setCurrentNote({
      ...note,
      // Convert the date (a string) to a Dayjs instance
      date: dayjs(note.date),
      [brewType + "Specific"]: note[brewType + "Specific"] || {},
    });
    setIsEditing(false);
    setActiveStep(0);
    setModalOpen(true);
  };

  // Helper function to format the description into neat rows.
  const renderFormattedDescription = (description) => {
    if (!description) return null;
    if (description.includes("\n")) {
      const lines = description.split("\n").filter((line) => line.trim() !== "");
      return lines.map((line, index) => {
        const colonIndex = line.indexOf(":");
        if (colonIndex !== -1) {
          const field = line.slice(0, colonIndex).trim();
          const value = line.slice(colonIndex + 1).trim();
          return (
            <Box
              key={index}
              sx={{ display: "flex", justifyContent: "space-between", mb: 0.5 }}
            >
              <Typography variant="caption" sx={{ fontWeight: "bold", mr: 1 }}>
                {field}:
              </Typography>
              <Typography variant="caption" sx={{ textAlign: "right" }}>
                {value}
              </Typography>
            </Box>
          );
        }
        return (
          <Typography key={index} variant="caption">
            {line}
          </Typography>
        );
      });
    }
    return <Typography variant="body2">{description}</Typography>;
  };

  const renderFreeTextField = () => (
    <TextField
      label="Enter your note here"
      multiline
      rows={8}
      fullWidth
      value={freeTextBody}
      onChange={(e) => setFreeTextBody(e.target.value)}
    />
  );

  const handleNext = () => setActiveStep((prev) => prev + 1);
  const handleBack = () => setActiveStep((prev) => prev - 1);

  const handleNoteSubmit = async () => {
    try {
      const timestamp = currentNote.date
        ? moment(currentNote.date).valueOf()
        : Date.now();

      const title = currentNote.category;
      let bodyContent = "";

      if (isFreeText) {
        bodyContent = freeTextBody || "";
      } else {
        const categoryKey = (currentNote.category || "")
          .replace(/[^a-zA-Z]/g, "")
          .toLowerCase();
        const schema =
          noteSchemas[brewType.toLowerCase()]?.streamlined?.[categoryKey];
        const specificData = currentNote[brewType + "Specific"] || {};

        if (!schema) {
          bodyContent = "No fields for this category.";
        } else {
          Object.keys(schema).forEach((field) => {
            const label = formatCategory(field);
            const value = specificData[field] || "";
            bodyContent += `${label}: ${value}\n`;
          });
        }
      }

      const noteDataForApi = {
        brewId,
        title,
        body: bodyContent,
        timestamp,
        tags: [],
      };

      let response;
      if (currentNote.NoteId) {
        response = await updateNote(currentNote.NoteId, noteDataForApi);
      } else {
        response = await addNote(noteDataForApi);
      }

      const categorizedNotes = response.notes.reduce((acc, note) => {
        const category = note.Title.toLowerCase();
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push({
          ...note,
          date: moment(note.Timestamp).format("YYYY-MM-DD"),
          description: note.Body,
        });
        return acc;
      }, {});

      setNotes(categorizedNotes);
      handleModalToggle(false);
    } catch (error) {
      // Optionally, set an error state here to display an alert.
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        // Step 0: Category & Date only.
        return (
          <>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Category</InputLabel>
              <Select
                value={currentNote.category}
                onChange={(e) =>
                  setCurrentNote({ ...currentNote, category: e.target.value })
                }
              >
                {Object.keys(noteSchemas[brewType.toLowerCase()]?.streamlined || {}).map((key) => (
                  <MenuItem key={key} value={key}>
                    {formatCategory(key)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date"
                value={currentNote.date}
                onChange={(date) =>
                  setCurrentNote({ ...currentNote, date })
                }
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </>
        );
      case 1:
        // Step 1: Details - Include Free Text Mode checkbox & appropriate fields.
        return (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isFreeText}
                  onChange={(e) => setIsFreeText(e.target.checked)}
                />
              }
              label="Free Text Mode"
              sx={{ "& .MuiTypography-root": { fontSize: "0.875rem" } }}
            />
            {isFreeText ? renderFreeTextField() : renderFieldsForCategory()}
          </>
        );
      case 2:
        // Step 2: Review & Submit
        return renderReviewContent();
      default:
        return null;
    }
  };

  const renderFieldsForCategory = () => {
    const categoryKey = currentNote.category.replace(/[^a-zA-Z]/g, "").toLowerCase();
    const schema = noteSchemas[brewType.toLowerCase()]?.streamlined[categoryKey];

    if (!schema)
      return <Typography>No fields available for this category</Typography>;

    return Object.entries(schema).map(([field, defaultValue]) => {
      const value = currentNote[brewType + "Specific"]?.[field] || "";
      const label = formatCategory(field);

      return (
        <TextField
          key={field}
          fullWidth
          label={label}
          value={Array.isArray(defaultValue) ? value.join(", ") : value}
          onChange={(e) => handleFieldChange(e, field, defaultValue)}
          multiline={field.toLowerCase().includes("note")}
          rows={field.toLowerCase().includes("note") ? 4 : 1}
          helperText={Array.isArray(defaultValue) && "Enter multiple values separated by commas"}
          sx={{ mb: 2 }}
        />
      );
    });
  };

  const handleFieldChange = (e, field, defaultValue) => {
    const value = Array.isArray(defaultValue)
      ? e.target.value.split(",").map((item) => item.trim())
      : e.target.value;
    setCurrentNote({
      ...currentNote,
      [brewType + "Specific"]: {
        ...currentNote[brewType + "Specific"],
        [field]: value,
      },
    });
  };

  const renderReviewContent = () => {
    const categoryKey = currentNote.category.replace(/[^a-zA-Z]/g, "").toLowerCase();
    const schema = noteSchemas[brewType.toLowerCase()]?.streamlined[categoryKey];
    const specificData = currentNote[brewType + "Specific"] || {};

    return Object.entries(schema || {}).map(([field, _]) => (
      <Typography key={field} variant="body2">
        <strong>{formatCategory(field)}:</strong>{" "}
        {Array.isArray(specificData[field])
          ? specificData[field].join(", ")
          : specificData[field] || "Not specified"}
      </Typography>
    ));
  };

  const renderDetailView = () => (
    <Box sx={{ position: "relative", p: 2 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        {formatCategory(currentNote.Title || currentNote.category)}
      </Typography>
      <Typography variant="body1" sx={{ whiteSpace: "pre-wrap" }}>
        {currentNote.description}
      </Typography>
      <Typography variant="body2" sx={{ mt: 2 }}>
        Date: {moment(currentNote.date).format("MM/DD/YYYY")}
      </Typography>
    </Box>
  );

  const allNotesFlat = Object.values(notes).flat();

  // If there's an error, render a centered Alert (not full-width) with a styled link back to /mybrews.
  if (error) {
    return (
      <>
        <Nav />
        <Box sx={{ maxWidth: 600, width: "90%", mx: "auto", my: 4 }}>
          <Alert severity="error" sx={{ textAlign: "center", mb: 3 }}>
            {error}
            <Box sx={{ mt: 1 }}>
              <Button
                component={Link}
                to="/mybrews"
                variant="outlined"
                size="small"
                sx={{
                  textTransform: "none",
                  fontWeight: "bold",
                }}
              >
                Back to My Brews
              </Button>
            </Box>
          </Alert>
        </Box>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Nav />
      <Grid container sx={{ backgroundColor: "#F8F9FA" }}>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            px: { xs: 5, md: 15 },
            py: 4,
          }}
        >
          <Grid
            item
            md={12}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item md={6}>
              <Typography variant="h4">{`Notes for ${brewName}`}</Typography>
            </Grid>
            <Grid
              item
              md={6}
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              sx={{ gap: 2 }}
            >
              {/* Only show the view toggle button if not on mobile */}
              {!isMobile && (
                <Button
                  onClick={() =>
                    setViewMode(viewMode === "kanban" ? "table" : "kanban")
                  }
                  variant="outlined"
                  sx={{
                    borderRadius: "1.5rem",
                    padding: { xs: "0.5rem 1rem", sm: "0.6rem 2rem" },
                  }}
                >
                  {viewMode === "kanban" ? "Switch to Table View" : "Switch to Kanban View"}
                </Button>
              )}
              <Button
                onClick={() => handleModalToggle(true)}
                variant="contained"
                sx={{
                  backgroundColor: "#031D2D",
                  "&:hover": { backgroundColor: "#000000" },
                  padding: { xs: "0.5rem 1rem", sm: "0.6rem 2rem" },
                  borderRadius: "1.5rem",
                  maxWidth: "100%",
                  minWidth: { xs: "0", sm: "15rem" },
                  transition: "background-color 0.3s ease",
                }}
                startIcon={<AddIcon />}
              >
                ADD NOTE
              </Button>
            </Grid>
          </Grid>
        </Grid>

        {allNotesFlat.length === 0 ? (
          <Grid item xs={12} textAlign="center">
            <Typography variant="h5" sx={{ my: 4 }}>
              Add a note to start tracking your brew journey!
            </Typography>
          </Grid>
        ) : viewMode === "kanban" ? (
          // Kanban view: Wrap in a container that's 90% wide and centered.
          <Box sx={{ width: "90%", mx: "auto", px: 2 }}>
            <Box
              sx={{
                display: "flex",
                gap: 2,
              }}
            >
              {orderedCategories.map((category) => {
                const categoryNotes = notes[category] || [];
                return (
                  <Box
                    key={category}
                    sx={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        backgroundColor: categoryColors[category] || "rgba(0,0,0,0.1)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 3,
                        height: 60,
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "0.85rem",
                          textAlign: "center",
                        }}
                      >
                        {formatCategory(category)}
                      </Typography>
                    </Box>
                    {/* Cards container */}
                    <Box
                      sx={{
                        mt: 2,
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: 2,
                        ...(categoryNotes.length > 4
                          ? { maxHeight: 500, overflowY: "auto" }
                          : {}),
                      }}
                    >
                      {categoryNotes.length > 0 ? (
                        categoryNotes.map((note) => (
                          <Box
                            key={note.NoteId}
                            onClick={() => handleCardClick(note)}
                            sx={{
                              width: "100%",
                              backgroundColor: "#fff",
                              borderRadius: 4,
                              py: 2,
                              mb: 2,
                              boxShadow: 1,
                              px: 1,
                              height: 150,
                              overflow: "hidden",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                              cursor: "pointer",
                            }}
                          >
                            <Box sx={{ overflow: "hidden", flexGrow: 1 }}>
                              {renderFormattedDescription(note.description)}
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                pt: 2,
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: 1.5,
                                  alignItems: "center",
                                }}
                              >
                                <LocalOfferOutlinedIcon sx={{ color: "rgb(255, 111, 97)" }} />
                                <Typography color="rgb(255, 111, 97)" variant="caption">
                                  {formatCategory(category)}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: 0.5,
                                  alignItems: "center",
                                }}
                              >
                                <CalendarMonthOutlinedIcon sx={{ color: "#031D2D" }} />
                                <Typography variant="caption" color="#031D2D">
                                  {moment(note.date).format("MM/DD/YYYY")}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        ))
                      ) : (
                        <Typography variant="body2" sx={{ mt: 2, textAlign: "center" }}>
                          No notes
                        </Typography>
                      )}
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        ) : (
          // Table view: center table and set width to 90%
          <Box
            sx={{
              px: { xs: 5, md: 15 },
              py: 2,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <TableContainer component={Paper} sx={{ width: "90%" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Category</TableCell>
                    <TableCell align="center">Description</TableCell>
                    <TableCell align="center">Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allNotesFlat
                    .sort((a, b) => moment(b.date).valueOf() - moment(a.date).valueOf())
                    .map((note) => (
                      <TableRow
                        key={note.NoteId}
                        sx={{
                          "&:hover": {
                            backgroundColor: "rgba(0,0,0,0.05)",
                            cursor: "pointer",
                          },
                        }}
                        onClick={() => handleCardClick(note)}
                      >
                        <TableCell align="center">
                          <Box
                            sx={{
                              backgroundColor:
                                categoryColors[note.Title.toLowerCase()] || "rgba(0,0,0,0.1)",
                              borderRadius: 2,
                              px: 1,
                              py: 0.5,
                              display: "inline-block",
                            }}
                          >
                            {formatCategory(note.Title)}
                          </Box>
                        </TableCell>
                        <TableCell align="center">{note.description}</TableCell>
                        <TableCell align="center">
                          {moment(note.date).format("MM/DD/YYYY")}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Grid>

      <Modal
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={modalOpen}
        onClose={() => handleModalToggle(false)}
      >
        <Box
          sx={{
            p: 4,
            width: { xs: "90%", md: "50%" },
            margin: "auto",
            backgroundColor: "#fff",
            borderRadius: 2,
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          {currentNote.NoteId && !isEditing ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Typography variant="h6">Note Details</Typography>
                <IconButton onClick={() => setIsEditing(true)}>
                  <EditIcon />
                </IconButton>
              </Box>
              {renderDetailView()}
              <Box sx={{ mt: 4, display: "flex", justifyContent: "flex-end" }}>
                <Button onClick={() => handleModalToggle(false)}>Close</Button>
              </Box>
            </>
          ) : (
            <>
              <Typography variant="h6">
                {currentNote.NoteId ? "Edit Note" : "Add Note"}
              </Typography>
              <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              {renderStepContent(activeStep)}
              <Box sx={{ mt: 4, display: "flex", justifyContent: "space-between" }}>
                <Button disabled={activeStep === 0} onClick={handleBack}>
                  Back
                </Button>
                <Button color="error" onClick={() => handleModalToggle(false)}>
                  Cancel
                </Button>
                {activeStep === steps.length - 1 ? (
                  <Button variant="contained" onClick={handleNoteSubmit}>
                    Submit
                  </Button>
                ) : (
                  <Button variant="contained" onClick={handleNext}>
                    Next
                  </Button>
                )}
              </Box>
            </>
          )}
        </Box>
      </Modal>

      <Footer />
    </>
  );
};

export default BrewNotes;
