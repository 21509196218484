import { refreshUserAttributes } from "./auth-utils";
const MAX_IMAGE_SIZE = 800; // Maximum dimension for images
const ALLOWED_IMAGE_TYPES = ['image/jpeg', 'image/png'];
const baseUrl = process.env.REACT_APP_API_URL || "https://api.beta.mybrewpage.com";

// Helper function for image optimization
const optimizeImage = async (imageBase64, imageType) => {
  try {
    const img = new Image();
    img.src = `data:${imageType};base64,${imageBase64}`;

    return new Promise((resolve, reject) => {
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        let width = img.width;
        let height = img.height;

        if (width > height && width > MAX_IMAGE_SIZE) {
          height = (height * MAX_IMAGE_SIZE) / width;
          width = MAX_IMAGE_SIZE;
        } else if (height > MAX_IMAGE_SIZE) {
          width = (width * MAX_IMAGE_SIZE) / height;
          height = MAX_IMAGE_SIZE;
        }

        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);
        const optimizedBase64 = canvas.toDataURL('image/jpeg', 0.8);
        resolve(optimizedBase64.split(',')[1]);
      };
      img.onerror = reject;
    });
  } catch (error) {
    console.error('Image optimization failed:', error);
    return imageBase64;
  }
};

const prepareHeaders = () => {
  const session = JSON.parse(localStorage.getItem('userSession') || '{}');
  const token = session?.token;
  const userInfo = session?.userInfo;

  if (token && userInfo?.sub) {
    return {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      'X-User-Sub': userInfo.sub,
    };
  }
  throw new Error('User is not authenticated or sub is missing');
};

const handleResponse = async (response) => {
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || 'An error occurred');
  }
  return response.json();
};

const createBrew = async (brewData) => {
  if (!brewData.brewName) {
    throw new Error('Brew name is required');
  }

  let optimizedBrewData = { ...brewData };
  if (brewData.imageBase64 && brewData.imageType) {
    if (!ALLOWED_IMAGE_TYPES.includes(brewData.imageType)) {
      throw new Error('Unsupported image type');
    }
    optimizedBrewData.imageBase64 = await optimizeImage(
      brewData.imageBase64,
      brewData.imageType
    );
  }
  const headers = prepareHeaders();
  const response = await fetch(`${baseUrl}/mybrews`, {
    method: 'POST',
    headers,
    body: JSON.stringify(optimizedBrewData),
  });
  return handleResponse(response);
};

const updateBrew = async (brewId, updates) => {
  if (!brewId || !updates.brewName || !updates.date) {
    throw new Error("Brew ID, brew name, and date are required.");
  }

  let optimizedUpdates = { ...updates, brewId }; // Add brewId to the request body

  // Optimize image if provided
  if (updates.imageBase64 && updates.imageType) {
    if (!ALLOWED_IMAGE_TYPES.includes(updates.imageType)) {
      throw new Error("Unsupported image type");
    }
    optimizedUpdates.imageBase64 = await optimizeImage(
      updates.imageBase64,
      updates.imageType
    );
  }

  const headers = prepareHeaders();

  // Send the PUT request with the updated fields
  const response = await fetch(`${baseUrl}/mybrews`, {
    method: "PUT",
    headers,
    body: JSON.stringify(optimizedUpdates),
  });

  return handleResponse(response);
};

const deleteBrew = async (brewId) => {
  const headers = prepareHeaders();
  const response = await fetch(`${baseUrl}/mybrews`, { // Endpoint without :brewId
    method: 'DELETE',
    headers,
    body: JSON.stringify({ brewId }), // Include `brewId` in the body
  });
  return handleResponse(response);
};


const getBrew = async (brewId) => {
  const headers = prepareHeaders();
  const response = await fetch(`${baseUrl}/mybrews/${brewId}`, {
    method: 'GET',
    headers,
  });
  return handleResponse(response);
};

const getUserPublishedBrews = async (userId) => {
  // const headers = prepareHeaders();
  const response = await fetch(`${baseUrl}/brews/${userId}`, {
    method: 'GET',
    // headers,
  });
  return handleResponse(response);
}
const getAllMyBrews = async () => {
  const headers = prepareHeaders();
  const response = await fetch(`${baseUrl}/mybrews`, {
    method: 'GET',
    headers,
  });
  return handleResponse(response);
};

// const updateSettings = async (attributes) => {
//   const session = JSON.parse(localStorage.getItem("userSession") || "{}");
//   const userSub = session?.userInfo?.sub;

//   if (!userSub) {
//     throw new Error("User is not authenticated or missing userSub.");
//   }

//   const headers = prepareHeaders();

//   const response = await fetch(`${baseUrl}/settings`, {
//     method: "PUT",
//     headers,
//     body: JSON.stringify({ userSub, attributes }),
//   });

//   if (!response.ok) {
//     const error = await response.json();
//     throw new Error(error.message || "Failed to update settings");
//   }

//   try {
//     // Fetch the latest user attributes after a successful update
//     const updatedSession = await refreshUserAttributes();
//     localStorage.setItem("userSession", JSON.stringify({
//       token: session.token, // Keep the existing token
//       userInfo: updatedSession.userInfo,
//     }));

//     return updatedSession.userInfo; // Return updated user information
//   } catch (error) {
//     console.error("Failed to retrieve latest user attributes:", error);
//     throw new Error("Settings updated, but user attributes could not be refreshed.");
//   }
// };
const updateSettings = async (attributes) => {
  const session = JSON.parse(localStorage.getItem("userSession") || "{}");
  const userSub = session?.userInfo?.sub;

  if (!userSub) {
    throw new Error("User is not authenticated or missing userSub.");
  }

  const headers = prepareHeaders();

  const response = await fetch(`${baseUrl}/settings`, {
    method: "PUT",
    headers,
    body: JSON.stringify({ userSub, attributes }),
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || "Failed to update settings");
  }

  try {
    // Fetch the latest user attributes (and new ID token) after a successful update
    const updatedSession = await refreshUserAttributes();
    // localStorage is already updated in refreshUserAttributes
    return updatedSession.userInfo;
  } catch (error) {
    console.error("Failed to retrieve latest user attributes:", error);
    throw new Error("Settings updated, but user attributes could not be refreshed.");
  }
};


// Notes API Helper Functions

const getBrewsWithNotes = async () => {
  const headers = prepareHeaders();
  const response = await fetch(`${baseUrl}/mynotes`, {
    method: "GET",
    headers,
  });
  return handleResponse(response);
};

const getNotes = async (brewId) => {
  if (!brewId) {
    throw new Error("Brew ID is required to fetch notes.");
  }
  const headers = prepareHeaders();
  const response = await fetch(`${baseUrl}/notes?brewId=${brewId}`, {
    method: "GET",
    headers,
  });
  return handleResponse(response);
};

const addNote = async (noteData) => {
  const headers = prepareHeaders();
  const response = await fetch(`${baseUrl}/notes`, {
    method: "POST",
    headers,
    body: JSON.stringify(noteData),
  });
  return handleResponse(response);
};

const updateNote = async (noteData) => {
  if (!noteData.BrewId || !noteData.NoteId) {
    throw new Error("Brew ID and Note ID are required to update a note.");
  }
  const headers = prepareHeaders();
  const response = await fetch(`${baseUrl}/notes`, {
    method: "PUT",
    headers,
    body: JSON.stringify(noteData),
  });
  return handleResponse(response);
};

const deleteNote = async (brewId, noteId) => {
  if (!brewId || !noteId) {
    throw new Error("Brew ID and Note ID are required to delete a note.");
  }
  const headers = prepareHeaders();
  const response = await fetch(`${baseUrl}/notes`, {
    method: "DELETE",
    headers,
    body: JSON.stringify({ BrewId: brewId, NoteId: noteId }),
  });
  return handleResponse(response);
};



export { createBrew, updateBrew, deleteBrew, getBrew, getUserPublishedBrews, getAllMyBrews, updateSettings, addNote, deleteNote, updateNote, getNotes, getBrewsWithNotes };
