import { CognitoUserPool, CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import { jwtDecode } from "jwt-decode";

// Initialize the Cognito User Pool
const userPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID, // Cognito User Pool ID from Amplify
  ClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID, // Cognito App Client ID from Amplify
});

/**
 * Authenticate a user with email and password.
 * @param {string} email - The user's email.
 * @param {string} password - The user's password.
 * @returns {Promise<Object>} - Resolves with the ID token and user info upon successful login.
 */

/**
 * Send a reset password link to the user's email.
 * @param {string} email - The user's email.
 * @returns {Promise<void>} - Resolves if the reset link is sent successfully.
 */
export const sendResetPasswordLink = (email) => {
  return new Promise((resolve, reject) => {
    const user = new CognitoUser({ Username: email, Pool: userPool });

    user.forgotPassword({
      onSuccess: () => {
        resolve();
      },
      onFailure: (err) => {
        reject(err);
      },
    });
  });
};

export const authenticateUser = (email, password) => {
  return new Promise((resolve, reject) => {
    const user = new CognitoUser({ Username: email, Pool: userPool });
    const authDetails = new AuthenticationDetails({ Username: email, Password: password });

    user.authenticateUser(authDetails, {
      onSuccess: (session) => {
        const idToken = session.getIdToken().getJwtToken();
        const userInfo = jwtDecode(idToken);
        // Add this storage line
        localStorage.setItem('userSession', JSON.stringify({
          token: idToken,
          userInfo
        }));

        resolve({ token: idToken, userInfo });
      },
      onFailure: (err) => {
        console.error("Authentication failed:", err);
        reject(err);
      },
    });
  });
};


/**
 * Get the current user's Cognito session and decode user information.
 * @returns {Promise<Object>} - Resolves with user info if a valid session exists.
 */
export const getCurrentSession = () => {
  return new Promise((resolve, reject) => {
    const currentUser = userPool.getCurrentUser();
    if (!currentUser) {
      reject("No user is currently logged in.");
      return;
    }

    currentUser.getSession((err, session) => {
      if (err || !session.isValid()) {
        reject("Invalid session.");
        return;
      }

      const idToken = session.getIdToken().getJwtToken();
      const userInfo = jwtDecode(idToken); // Decode the ID token for user attributes
      resolve({ session, userInfo });
    });
  });
};

export const checkSession = () => {
  const currentUser = userPool.getCurrentUser();
  if (!currentUser) return null;

  return new Promise((resolve, reject) => {
    currentUser.getSession((err, session) => {
      if (err) {
        reject(err);
        return;
      }

      if (session.isValid()) {
        const idToken = session.getIdToken().getJwtToken();
        const userInfo = jwtDecode(idToken);
        resolve({ token: idToken, userInfo });
      } else {
        // Attempt to refresh the session if it is invalid
        currentUser.refreshSession(session.getRefreshToken(), (refreshErr, newSession) => {
          if (refreshErr) {
            console.error("Error refreshing session:", refreshErr);
            reject(refreshErr);
            return;
          }

          const idToken = newSession.getIdToken().getJwtToken();
          const userInfo = jwtDecode(idToken);
          localStorage.setItem('userSession', JSON.stringify({ token: idToken, userInfo }));
          resolve({ token: idToken, userInfo });
        });
      }
    });
  });
};

export const logoutUser = () => {
  const currentUser = userPool.getCurrentUser();
  if (currentUser) {
    currentUser.signOut();
    localStorage.removeItem('userSession');
  }
};

/**
 * Sign up a new user with Cognito.
 * @param {string} email - The user's email.
 * @param {string} password - The user's password.
 * @param {Object} attributes - Additional user attributes (e.g., name).
 * @returns {Promise<void>} - Resolves if sign-up is successful.
 */
export const signUpUser = (email, password, attributes = {}) => {
  return new Promise((resolve, reject) => {
    // Map attributes to Cognito format
    const attributeList = Object.entries(attributes).map(([Name, Value]) => ({
      Name: Name === 'given_name' ? 'given_name' :
        Name === 'family_name' ? 'family_name' : Name,
      Value
    }));

    userPool.signUp(
      email,
      password,
      attributeList,
      null,
      (err, result) => {
        if (err) {
          console.error("Error during sign-up:", err);
          reject(err);
          return;
        }
        resolve(result);
      }
    );
  });
};

/**
 * Refresh the user's Cognito session.
 * @returns {Promise<Object>} - Resolves with refreshed session and updated user info.
 */
export const refreshSession = () => {
  return new Promise((resolve, reject) => {
    const currentUser = userPool.getCurrentUser();
    if (!currentUser) {
      reject("No user is currently logged in.");
      return;
    }

    currentUser.refreshSession(
      currentUser.getSignInUserSession().getRefreshToken(),
      (err, session) => {
        if (err) {
          console.error("Error refreshing session:", err);
          reject(err);
          return;
        }

        const idToken = session.getIdToken().getJwtToken();
        const userInfo = jwtDecode(idToken);

        // Update localStorage with refreshed session and user info
        localStorage.setItem(
          "userSession",
          JSON.stringify({
            token: idToken,
            userInfo,
          })
        );

        // Now resolve with an object that matches checkSession
        resolve({ token: idToken, userInfo });
      }
    );
  });
};



// export const refreshUserAttributes = () => {
//   return new Promise((resolve, reject) => {
//     const currentUser = userPool.getCurrentUser();

//     if (!currentUser) {
//       reject(new Error("No user is currently logged in."));
//       return;
//     }

//     currentUser.getSession((sessionErr, session) => {
//       if (sessionErr || !session.isValid()) {
//         console.error("Invalid session. Attempting to refresh session...");
//         currentUser.refreshSession(currentUser.getSignInUserSession().getRefreshToken(), (refreshErr, refreshedSession) => {
//           if (refreshErr) {
//             console.error("Error refreshing session:", refreshErr);
//             reject(new Error("Failed to refresh session."));
//             return;
//           }
//           fetchUserAttributesAndUpdateStorage(currentUser, refreshedSession, resolve, reject);
//         });
//       } else {
//         fetchUserAttributesAndUpdateStorage(currentUser, session, resolve, reject);
//       }
//     });
//   });
// };
export const refreshUserAttributes = () => {
  return new Promise((resolve, reject) => {
    const currentUser = userPool.getCurrentUser();

    if (!currentUser) {
      reject(new Error("No user is currently logged in."));
      return;
    }

    currentUser.getSession((sessionErr, session) => {
      if (sessionErr) {
        console.error("Failed to get session:", sessionErr);
        reject(new Error("Failed to get session."));
        return;
      }

      // Force refresh the session to obtain a new ID token with updated attributes
      currentUser.refreshSession(session.getRefreshToken(), (refreshErr, refreshedSession) => {
        if (refreshErr) {
          console.error("Error refreshing session:", refreshErr);
          reject(new Error("Failed to refresh session."));
          return;
        }
        fetchUserAttributesAndUpdateStorage(currentUser, refreshedSession, resolve, reject);
      });
    });
  });
};

// const fetchUserAttributesAndUpdateStorage = (currentUser, session, resolve, reject) => {
//   currentUser.getUserAttributes((err, attributes) => {
//     if (err) {
//       console.error("Error retrieving user attributes:", err);
//       reject(new Error("Failed to retrieve user attributes."));
//       return;
//     }

//     const userInfo = attributes.reduce((acc, attribute) => {
//       acc[attribute.Name] = attribute.Value;
//       return acc;
//     }, {});

//     // Update localStorage with refreshed attributes
//     localStorage.setItem(
//       "userSession",
//       JSON.stringify({
//         token: session.getIdToken().getJwtToken(),
//         userInfo,
//       })
//     );

//     resolve({ userInfo });
//   });
// };
const fetchUserAttributesAndUpdateStorage = (currentUser, session, resolve, reject) => {
  currentUser.getUserAttributes((err, attributes) => {
    if (err) {
      console.error("Error retrieving user attributes:", err);
      reject(new Error("Failed to retrieve user attributes."));
      return;
    }

    const userInfo = attributes.reduce((acc, attribute) => {
      acc[attribute.Name] = attribute.Value;
      return acc;
    }, {});

    const newToken = session.getIdToken().getJwtToken();

    // Update localStorage with the new token and attributes
    localStorage.setItem(
      "userSession",
      JSON.stringify({
        token: newToken,
        userInfo,
      })
    );

    resolve({ token: newToken, userInfo });
  });
};

export { userPool }; // Ensure the `userPool` is exported for other files.