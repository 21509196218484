// SessionProvider.js
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { checkSession, refreshSession } from '../services/auth-utils';
import { setSession, clearSession } from '../redux/slices/notesSlices/AuthSlice';
import { useNavigate } from 'react-router-dom';
import { Modal, Box, Button, Typography } from '@mui/material';
import { userPool } from '../services/auth-utils'; // For additional debugging if needed

export const SessionProvider = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sessionChecked, setSessionChecked] = useState(false);

  useEffect(() => {
    let refreshTimeout;

    const handleSessionInvalid = () => {
      const storedSession = localStorage.getItem('userSession');
      const hadSession = storedSession && JSON.parse(storedSession).token;
      if (hadSession) {
        console.warn('Session invalid: clearing session and showing popup.');
        dispatch(clearSession());
        setIsModalOpen(true);
      }
      setSessionChecked(true);
    };

    const scheduleTokenRefresh = (expiresIn) => {
      if (refreshTimeout) clearTimeout(refreshTimeout);
      // Schedule the refresh one minute before token expiration.
      const delay = expiresIn > 60000 ? expiresIn - 60000 : 0;
      // console.log(`Scheduling token refresh in ${delay}ms (expiresIn: ${expiresIn}ms)`);
      refreshTimeout = setTimeout(() => refreshTokens(), delay);
    };

    const refreshTokens = async () => {
      try {
        // console.log('Attempting to refresh session...');
        const session = await refreshSession();
        if (session) {
          const tokenExpiration = session.userInfo.exp * 1000;
          const now = Date.now();
          const expiresIn = tokenExpiration - now;
          // console.log('Token refreshed successfully. New expiration in', expiresIn, 'ms');
          dispatch(setSession({
            isAuthenticated: true,
            token: session.token,
            userInfo: session.userInfo,
          }));
          scheduleTokenRefresh(expiresIn);
          setSessionChecked(true);
        } else {
          console.warn('refreshSession() returned no session.');
          handleSessionInvalid();
        }
      } catch (error) {
        console.error('Failed to refresh session:', error);
        handleSessionInvalid();
      }
    };

    const initSession = async () => {
      try {
        // console.log('Initializing session...');
        const session = await checkSession();
        if (session) {
          const tokenExpiration = session.userInfo.exp * 1000;
          const now = Date.now();
          const expiresIn = tokenExpiration - now;
          // console.log('Session found. Expires in:', expiresIn, 'ms');
          // Force refresh if token expires in less than 60 seconds.
          if (expiresIn <= 60000) {
            // console.log('Token expiring soon, refreshing now...');
            refreshTokens();
          } else {
            dispatch(setSession({
              isAuthenticated: true,
              token: session.token,
              userInfo: session.userInfo,
            }));
            scheduleTokenRefresh(expiresIn);
            setSessionChecked(true);
          }
        } else {
          // console.log('No session found on initialization.');
          setSessionChecked(true);
        }
      } catch (error) {
        console.error('Session initialization failed:', error);
        handleSessionInvalid();
      }
    };

    initSession();

    return () => {
      if (refreshTimeout) clearTimeout(refreshTimeout);
    };
  }, [dispatch, navigate]);

  if (!sessionChecked) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {children}
      <Modal
        open={isModalOpen}
        onClose={(event, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
          setIsModalOpen(false);
        }}
        disableEscapeKeyDown
        aria-labelledby="session-invalid-modal-title"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: { xs: '90%', sm: '70%', md: '50%' },
            padding: 2,
            backgroundColor: '#fff',
            borderRadius: '1rem',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            textAlign: 'center',
          }}
        >
          <Typography variant="h6" sx={{ color: '#FF6F61', mb: 2 }}>
            Session Invalid
          </Typography>
          <Typography variant="body1" sx={{ color: '#555', mb: 3 }}>
            Your session has expired. Please sign in to continue.
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
            <Button
              variant="contained"
              sx={{ backgroundColor: '#FF6F61', color: '#fff' }}
              onClick={() => {
                setIsModalOpen(false);
                navigate('/');
              }}
            >
              Home
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: '#FF6F61', color: '#fff' }}
              onClick={() => {
                setIsModalOpen(false);
                navigate('/signin');
              }}
            >
              Sign In
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default SessionProvider;
